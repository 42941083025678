<template>
  <div class="container card-login">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-body">
            <h3 class="title">ลงชื่อเข้าใช้</h3>
            <div class="input-group flex-nowrap my-4 has-validation">
              <span class="input-group-text" id="input-username">
                <img src="~@/assets/user-icon-input.svg" />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="ชื่อผู้ใช้"
                aria-label="Username"
                v-model="data.username"
                :class="{ 'is-invalid': errors }"
              />
            </div>
            <div class="input-group flex-nowrap my-4">
              <span class="input-group-text" id="input-password">
                <img src="~@/assets/password-icon-input.svg" />
              </span>
              <input
                class="form-control"
                placeholder="รหัสผ่าน"
                aria-label="Password"
                v-model="data.password"
                :type="isPasswordShow ? 'text' : 'password'"
                :class="{ 'is-invalid': errors }"
              />
              <span
                class="input-group-text toggle-password"
                @click="isPasswordShow = !isPasswordShow"
              >
                <img :src="togglePasswordIcon" />
              </span>
            </div>
            <div class="form-check text-end my-4">
              <label class="form-check-label" for="rememberMe">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="exampleRadios"
                  id="rememberMe"
                  checked
                />
                จำบัญชีนี้
              </label>
            </div>
            <div class="d-grid gap-2 mt-4">
              <button class="btn-submit" type="button" @click="login">
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isPasswordShow: false,
      data: {
        // username: null,
        // password: null,
        username: "pptesteru01",
        password: "123456",
      },
      errors: false,
    };
  },
  computed: {
    togglePasswordIcon() {
      if (this.isPasswordShow) {
        return require("@/assets/eye-slash-icon.svg");
      } else {
        return require("@/assets/eye-icon.svg");
      }
    },
  },
  methods: {
    async login() {
      this.errors = !(await this.$store.dispatch("login", this.data));
      if (!this.errors) {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card-login {
  .title {
    text-align: center;
    font-weight: 500;
    font-size: 30px;
  }
  label.username {
    position: relative;
    display: block;
    &:before {
      content: "";
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
    }
  }
  .input-group {
    span {
      background-color: $gray-input;
    }
    .form-control {
      background-color: $gray-input;
    }
    .toggle-password {
      cursor: pointer;
    }
  }
  .btn-submit {
    background-color: $submit-color;
    color: $white;
    border-radius: 12px;
    padding: 12px;
    border: none;
  }
  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: #000;
    opacity: 1;
  }
}
</style>
